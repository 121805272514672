<script setup>
import { defineProps, ref, toRaw } from "vue";

import { defineExpose } from "vue";
import InputComponent from "./InputComponent.vue";
import SelectComponent from "./SelectComponent.vue";
import TextAreaComponent from "./TextAreaComponent.vue";

// Define props
const props = defineProps({
  form: {
    type: Object,
    required: true,
  },
});

// Track whether this accordion is open or closed
const isAccordionOpen = ref(false);

const toggleAccordion = () => {
  isAccordionOpen.value = !isAccordionOpen.value;
};

const getFormField = (field) => {
  const baseProps = {
    id: field?.id,
    label: field?.label,
    required: field?.isRequired,
    modelValue: field?.value,
    minLength: field?.minimumLength,
    maxLength: field?.maximumLength,
    defaultValue: field?.defaultValue,
    placeholder: field?.placeholder,
    pattern: field?.regExp,
    options: field?.options,
    allowMultiple: field?.allowMultiple,
  };
  //  console.log("field: " + field);
  switch (field.componentName) {
    case "Text":
    case "Short Text":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "text",
        },
      };
    case "Long Text":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "textarea",
        },
      };
    case "Email":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "email",
        },
      };
    case "Password":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "password",
        },
      };
    case "Number":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "number",
        },
      };
    case "Rich Text":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "richtext",
        },
      };
    case "Telephone":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "tel",
        },
      };
    case "Radio":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "radio",
        },
      };
    case "Range":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "range",
        },
      };
    case "Checkbox":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "checkbox",
        },
      };
    case "Date":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "date",
        },
      };
    case "Datetime":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "datetime-local",
        },
      };
    case "Week":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "week",
        },
      };
    case "Month":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "month",
        },
      };
    case "Time":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "time",
        },
      };
    case "Link":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "url",
        },
      };
    case "Single Media":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "file",
        },
      };
    case "Boolean":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "checkbox",
        },
      };
    case "Multiple Media":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "file",
          multiple2: true,
        },
      };

    case "Image":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "image",
        },
      };
    case "Enumeration":
      return {
        component: SelectComponent,
        props: {
          ...baseProps,
          options: field.options || [],
        },
      };
    case "DropDown":
      return {
        component: TextAreaComponent,
        props: {
          ...baseProps,
          options: field.options || [],
        },
      };
    case "CheckBox":
      return {
        component: TextAreaComponent,
        props: {
          ...baseProps,
          options: field.options || [],
        },
      };
    case "RadioButton":
      return {
        component: TextAreaComponent,
        props: {
          ...baseProps,
          options: field.options || [],
        },
      };
    default:
      return null;
  }
};
</script>

<template>
  <div class="accordion" :id="`accordion-${props.form.id}`">
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button
          class="accordion-button"
          type="button"
          :class="{ collapsed: !isAccordionOpen }"
          @click="toggleAccordion"
          aria-expanded="isAccordionOpen"
          :aria-controls="`collapse-${props.form.id}`"
        >
          <b>{{ props.form.label }}</b>
        </button>
      </h2>
      <div
        :id="`collapse-${props.form.id}`"
        class="accordion-collapse collapse"
        :class="{ show: isAccordionOpen }"
        v-if="props.form.childComponents.length"
      >
        <div class="accordion-body">
          <div class="colorclass">
            <template
              v-for="(item, index) in props.form.childComponents"
              :key="index"
            >
              <template v-if="item.componentName !== 'Components'">
                <component
                  :is="getFormField(item)?.component"
                  v-bind="getFormField(item)?.props"
                  @update:modelValue="(value) => (item.value = value)"
                  @update:options="(value) => (item.options = value)"
                />
              </template>
              <template v-else>
                <ContentManagerComponent :form="item" />
              </template>
            </template>
            <!-- <component
              v-for="(field, index) in props.form.childComponents"
              :key="index"
              :is="getFormField(field)?.component"
              v-bind="getFormField(field)?.props"
              @update:modelValue="(value) => (field.value = value)"
              @update:options="(value) => (field.options = value)"
            /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.accordion-item {
  margin: 2px;
}

.labelstyle {
  margin: 2vh 0vh 0vh 0vh;
}

.accordion-button {
  width: 100%;
  color: #0146f4 !important;
  margin: 0;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  background-color: white;
}

.inputstyle {
  width: 100%;
}

.colorclass {
  color: #0146f4;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.borderedContainer2 {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  color: #0146f4;
}

.form-preview-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 0rem;
}

input {
  width: auto !important;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  color: black;
  padding: 10px;
}

input::placeholder {
  color: #999;
}

.accordion {
  width: 98%;
}
</style>
