<script setup>
import { defineProps, defineEmits, ref, watch } from "vue";

// Define props
const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  modelValue: {
    type: [String, Array],
    default: () => [],
  },
  label: {
    type: String,
    default: "Select an option",
  },
  required: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Array,
    default: () => [],
  },
  allowMultiple: {
    type: Boolean,
    default: false,
  },
  defaultValue: {
    type: String,
    default: "",
  },
});

// Emit event to update modelValue
const emit = defineEmits(["update:modelValue"]);

// Initialize selectedValue based on allowMultiple and modelValue or defaultValue
const selectedValue = ref(
  props.allowMultiple
    ? Array.isArray(props.modelValue) && props.modelValue.length
      ? props.modelValue
      : [props.defaultValue]
    : props.modelValue || props.defaultValue
);

// Watch for changes in modelValue and defaultValue and update selectedValue accordingly
watch(
  () => props.modelValue,
  (newValue) => {
    selectedValue.value = props.allowMultiple
      ? Array.isArray(newValue) && newValue.length
        ? newValue
        : [props.defaultValue]
      : newValue || props.defaultValue;
  },
  { immediate: true }
);

// Watch selectedValue and emit the correct type based on allowMultiple
watch(selectedValue, (newValue) => {
  console.log("new Value", newValue);
  emit("update:modelValue", newValue);
});

// Handle change event to update selectedValue correctly based on allowMultiple
const handleChange = (event) => {
  const select = event.target;
  selectedValue.value = props.allowMultiple
    ? Array.from(select.selectedOptions).map((option) => option.value)
    : select.value;
};
</script>

<template>
  <div class="select-preview">
    <label :for="id">
      {{ label }}
      <span class="required" v-if="required">(Required)</span>
    </label>
    <select
      :id="id"
      :multiple="allowMultiple"
      @change="handleChange"
      :required="required"
      v-model="selectedValue"
    >
      <option value="" disabled>Select an option</option>
      <option v-for="option in options" :key="option" :value="option">
        {{ option }}
      </option>
    </select>
  </div>
</template>

<style lang="scss" scoped>
.select-preview {
  width: 18vw;
}
</style>
